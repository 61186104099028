.certificatePage .tileContainer {
    justify-content: unset;
}

.certificatePage .buttonTile .content {
    display: flex;
    justify-content: center;
    align-items: center;
}


    .certificatePage .buttonTile .content .certificateDocument {
        text-align: center;
    }
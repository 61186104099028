.my-addresses {
    font-weight: 300;
}

.my-addresses-link {
    margin-right: 15px;
}

.my-addresses-add-link {
    margin-left: 15px;
    font-size: 16px;
}

.address-title h3 {
    margin: 50px 0px 30px 0px;
    text-align: left;
}

    .address-title h3 .my-addresses-link {
        font-size: medium;
    }

.my-address {
    width: 230px;
    margin: 5px 15px 5px 0px;
    padding: 5px;
    display: inline-block;
    border: solid;
    border-radius: 5px;
    border-color: #C1C1C1;
}


.shipping-address-container {
    display: flex;
    flex-wrap: wrap;
}

.my-address .name {
    font-weight: 700;
}

.my-address .addressLineFormat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.default-label {
    margin-bottom: 2px;
    /*   font-style: italic;*/
    color: black;
    /*  text-decoration: underline;*/
}

.edit-address-title h2 {
    text-align: left;
}

.address-form {
    width: 60%;
}

    .address-form input,
    .address-form select {
        margin-right: 10px;
    }

    .address-form .default-address-checkbox {
        margin: 15px;
    }

    .address-form .column:first-child .spacer {
        padding: 0px;
    }

    .address-form .column:last-child .spacer {
        padding: 0px;
    }

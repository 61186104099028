.helpPopup {
    width: 100%;
    display: inline-block;
    font-family: 'Montserrat';
    text-align: left;
}

/*
.tabs .helpPopup {
    margin-top: 40px;
}

    .helpPopup .topSection {
        display: flex;
        flex-flow: nowrap;
        align-content: center;
        width: 100%;
        min-height: 50px;
        color: #fff;
        background: #002B44;
        gap: 20px;
    }

        .helpPopup .topSection h4 {
            color: #fff;
            text-align: center;
        }

        .helpPopup .topSection .left {
            padding: 20px 0px 0px 20px;
            flex-grow: 1;
        }

        .helpPopup .topSection .center {
            flex-grow: 4;
        }

        .helpPopup .topSection .right {
            padding: 20px 20px 0px 0px;
            flex-grow: 1;
            text-align: right;
        }
*/

    .helpPopup .article-content {
        font-size: 16px;
        padding: 10px;
        max-height: 80vh;
        overflow-y: auto;
        width: inherit;
        display: inline-block;
        white-space: normal;
    }

.tabs .helpPopup .article-content {
    max-height: 70vh;
    
}

.helpPopup .article-content {
    color: #5A5B5D;
}

.helpPopup .article-content p {
}

        .helpPopup .article-content img {
            display: block;
        }

        .helpPopup .article-content .image-wrapper a {
            pointer-events: none;
            cursor: default;
        }

        .helpPopup .article-content .image-wrapper .caption {
            display: none;
        }
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.order-placement-layout {
    padding: 10px;
    overflow: hidden;
}

.pouch-image {
    max-width: 170px;
}

.header-section {
    text-align: left;
}

.submit {
    float: right;
    padding: 20px 0pc 0px 0px;
}

.nextStepsText {
    text-align: left;
    font-size: 15px;
    line-height: 32px;
}

.artworkLayout .modal .modalContent {
    text-align: left;
    padding: 0px 10px 20px 10px;
}

.artworkLayout .modal .modalContent .article-header {
}

.artworkLayout .modal .modalContent .content {
    max-height: calc(65vh);
    overflow-y: auto;
    margin-top: 25px;
}

.artworkLayout .modal .modalContent h1,
h2,
h3 {
    font-size: 24px;
    font-weight: 600;
    color: #002b44;
}

.modal-hidden {
    display: none;
}

.skuPage .skusPage .numberEntry .section h3 {
    text-align: left;
    /* width: 200%;*/
    display: inline-block;
}

.skuPage .skusPage .section .estimateList h3 {
    text-align: left;
    margin: 10px 10px 20px 40px;
}

.skuPage .notice {
    font-style: italic;
}

.skuPage .small {
    font-size: small;
    margin: 0px;
}

.skuPage .text-action-container {
    height: 36px;
    display: inline;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 10px;
}

.skuPage .text-action-container-textbox {
    flex-grow: 1;
    width: 13%;
    display: inline-block;
}

.skuPage .inline .text-action-container-textbox {
    margin: 10px;
    /*    float: right;*/
}
.skuPage .text-action-container-textbox-sm {
    width: 7%;
    display: inline-block;
    margin: 0px 10px 0px 15px;
}



.skuPage .text-action-container-button {
    margin: 0 0 0 0px;
}

.skuPage .error-notification {
    background: var(--color-error);
    color: #fff;
    padding: 4px;
    font-weight: bold;
}

.skuPage .section {
    display: block;
    margin-bottom: 40px;
}

.skuPage .numberEntry {
    width: 100%;
    margin: 40px;
}


/*    .skuPage .pageContent .test .section .h3{
        text-align:left;
    }*/

.skuPage .section .alert {
    color: #BB1128;
    font-style: italic;
    margin-top: 10px;
}


/*************************************************************/
/* MEDIA QUIERIES                                            */
/*************************************************************/
@media (max-width: 1024px) {
}

@media (max-width: 640px) {

    .skuPage .text-action-container-button {
        padding: 0px 15px 0px 15px;
        margin: 0px;
        min-width: unset;
    }
}

@media (max-width: 320px) {
}

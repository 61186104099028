/* .unwindDirectionsDropdown {
}

.unwindDirectionsDropdown .customDropdown .listItem .imageContainer {
  width: 95px;
  display: inline-block;
  float: left;
}

.unwindDirectionsDropdown .customDropdown .listItem .imageContainer img {
  width: 80px;
  vertical-align: middle;
}

.unwindDirectionsDropdown .customDropdown .listItem span {
  vertical-align: middle;
  display: inline-block;
  float: left;
  padding-top: 12px;
}

.unwindDirectionsDropdown
  .customDropdown
  .optionsContainer
  .listItem.disabled:hover,
.unwindDirectionsDropdown .customDropdown .optionsContainer .listItem.disabled {
  background: #1886bb;
  color: #fff;
  padding: 10px 0px 10px 3px;
  font-weight: 600;
}

.unwindDirectionsDropdown
  .customDropdown
  .optionsContainer
  .listItem.disabled
  span {
  padding: 0px;
} */

.unwindirections-dropdown {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

.unwindirections-dropdown .list-item {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
}
/* .unwindirections-dropdown .list-item:hover {
    background-color: #00B3E8;
} */
.unwindirections-dropdown .list-item .text {
    padding-left: 5px;
    flex: 1
}

.unwindirections-dropdown .list-item img {
    width: 80px
}
.sectionTitle {
    /*width: auto;*/
    padding-bottom: 5px;
    padding-top: 10px;
}

.sectionTitle h3 {
    display: flex;
}

.required {
    color: #bb1128;
    padding-left: 5px;
}


.backSplash {
    z-index: 10000;    
}

.spinner-circle {
    z-index: 20000;
    position: fixed;
    left: calc(50% - var(--spinner-size)/2);
    top: calc(50% - var(--spinner-size)/2);
}


.spinner-text {
    z-index: 20000;
    position: fixed;
    top: calc(50% + var(--spinner-size));
    left: 0px;
    width: 100%;
    display: block;
    text-align: center;
}


html {
    scroll-behavior: smooth;
}

.text-align-center {
    text-align: center;
}

.tileContainer {
    margin: 30px 0px 0px 0px;
}

.breadcrumbs {
    margin: 20px 0px 50px 0px;
}

.default-border {
    border: 2px solid rgb(0 0 0 / 5%);
}

.active-border {
    border: 2px solid #00b3e8;
}

.btn:disabled {
    opacity: 0.5;
}

.order .loader-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    z-index: 99999999;
}

.threeDModel {
    border: none;
    overflow: hidden;
    background: #fff;
}

.menu-item {
    padding: 5px 0px 5px 0px;
}

.menu-item:hover {
    background: #eee;
    color: #000;
}

.contact-pop-over {
    margin: 20px 20px 0px 0px;
}

.country-pop-over {
    margin: 20px 20px 0px 0px;
    display: block;
    float: right;
}

.country-pop-over .btn-primary-outline {
    color: #000;
    text-transform: unset;
    margin: 0px 0px 0px 40px;
    padding: 0.47em;
    width: 200px;
    background: #fff;
}

.country-pop-over .pop-over {
    width: 300px;
    display: block;
    position: absolute;
    z-index: 20000;
    padding: 0px 0px 0px 10px;
    color: #959596;
    background: #ffffff;
    border: 1.5px solid #f47745;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 6px;
    font-family: "Open Sans";
    font-weight: normal;
    font-size: 12px;
}

.country-pop-over .pop-over .triangle {
    margin: 0px 0px 0px 130px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #f47745 transparent transparent transparent;
}

.icon {
    vertical-align: middle;
}

.orderReference h3, p {
    text-align: left;
}

.orderCompleteLayout h3 {
    text-align: left;
}

.sectionTitle .icon {
    padding: 0px 0px 0px 10px;
}

@media (max-width: 640px) {
    .country-pop-over {
        display: none;
    }

    .contact-pop-over {
        margin: 15px 0px 0px 10px;
    }

    .contact-pop-over .btn.btn-primary {
        margin: 0px;
    }

    btn.signout {
        display: none;
    }

    .btn {
        /*padding: 0px;*/
        min-width: 100px;
    }

    .btn.back span,
    .btn.next span,
    .btn.add span {
        display: none;
    }

    .btn.back:after {
        content: "<";
    }

    .btn.next:after {
        content: ">";
    }

    .btn.add:after {
        content: "+";
    }
}

#mycontainerCheck {
    min-height: 520px;
}


#chatContainer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: inline-block;
    z-index: 10000;
}

iframe#chatFrame {
    position: relative;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    border: none;
}
.additional-shipping-address-row {
    display: flex;
    padding: 0.75em;
    flex-direction: row;
    gap: 1.5em;
}
.additional-shipping-address-row-text {
    flex: 1;
}

.additional-shipping-address-row-buttons {
    margin-left: auto;
    cursor: pointer;
}
.customerOnHold {
}

.customerOnHold .contact {
}

.customerOnHold .contact p {
    color: var(--color-orange);
    font-size: 16px;
    line-height: 16px;
}

.customerOnHold .contact a {
    color: var(--color-orange);
}

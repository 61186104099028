.pouchPreview {
}

    .hidden {
        visibility: hidden;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }

    .pouchPreview .toolbar {
        display: inline-block;
        width: 100%;
        height: 20px;
        text-align: center;
    }

        .pouchPreview .toolbar .swatch {            
            display: inline-block;
            width: 20px;
            height: 20px;            
            margin: 0px 10px 0px 10px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            border-radius: 15px;
            cursor: pointer;
        }


        .pouchPreview .container {
            background: #fff;
            padding: 0px;
            max-width: unset;
            min-width: unset;            
            position: fixed;
            z-index: 1000;
            right: 50px;
            top: 150px;
            min-width: 340px;
            min-height: 320px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            border: 1px solid #aaa;
            -webkit-box-shadow: 5px 5px 8px -1px rgba(0,0,0,0.39);
            box-shadow: 5px 5px 8px -1px rgba(0,0,0,0.39);
        }

        .pouchPreview .container .frame {
            display: block;
            min-height: 320px;
        }

            .pouchPreview .container.maximized .frame {
                display: block;
                min-height: 670px;
            }

            .pouchPreview .container.minimized .frame {
                display: block;
                min-height: 100px;
            }

            .pouchPreview .container.minimized {
                right: 50px;
                top: 150px;
                min-width: 100px;
                min-height: 120px;
            }

            .pouchPreview .container.maximized {
                position: fixed;
                width: 640px;
                margin-top: 8%; 
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%);
            }



    .pouchPreview .container .icon {
        margin: 5px 5px 0px 0px;
        cursor: pointer;
    }

@media (max-width: 1200px) {
    .pouchPreview .container {
        padding: 0px;
        max-width: unset;
        min-width: unset;
        position: relative;
        z-index: unset;
        right: unset;
        top: unset;
        border: unset;
        -webkit-box-shadow: unset;
        box-shadow: unset;
        text-align: center;
    }

        .pouchPreview .container .icon {
            display: none;
        }

}


.card .container .tile-error {
    color: var(--color-error);
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 5px;
}

.artworkLayout .modal .modalContent {
    text-align: left;
    padding: 0px 10px 20px 10px;
}

    .artworkLayout .modal .modalContent .article-header {
    }

    .artworkLayout .modal .modalContent .content {
        max-height: calc(65vh);
        overflow-y: auto;
        margin-top: 25px;
    }

    .artworkLayout .modal .modalContent h1, h2, h3 {
        font-size: 24px;
        font-weight: 600;
        color: #002b44;
        text-align: center;
    }

.artworkLayout .artwork-waive-content {
    display: grid;
}
.modal-hidden {
    display: none;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.uploadLink.buttonTile {
    width: 200px;
    min-height: 200px;
}

    .uploadLink.buttonTile h4 {
        margin: 4px;
        text-align: center;
        max-height: 60px;
        overflow-y: hidden;
        padding-bottom: 10px;
    }

    .uploadLink.buttonTile .fileName {
        position: absolute;
        width: inherit;
        top: 150px;
    }

    .uploadLink.buttonTile .content {
        position: relative;
        height: 200px;
    }

    .uploadLink.buttonTile input[type="file"] {
        display: none;
    }

.uploadLink .previewImage {
    width: 100%;
    /*  height: 100%; */
    object-fit: contain;
}

.progress.indicator {
    position: absolute;
    left: 62px;
    top: 60px;
}

    .progress.indicator.failure {
        width: 80px;
        height: 80px;
        background: red;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
    }

        .progress.indicator.failure .circle {
            margin: 10px;
            width: 60px;
            height: 60px;
            background: #fff;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }

            .progress.indicator.failure .circle .icon {
                padding: 15px;
                color: red;
            }

    .progress.indicator .value {
        position: absolute;
        color: #7cbd42;
        left: 0;
        top: 0;
        width: inherit;
        height: inherit;
        text-align: center;
        vertical-align: middle;
    }

    .progress.indicator .icon {
        height: 30px;
        width: 30px;
        stroke-width: 6px;
        vertical-align: middle;
    }

        .progress.indicator .icon.thin {
            stroke-width: 3px;
            color: #f47745;
        }

.spinner {
    display: block;
    display: block;
    margin: 5px 0px 0px 5px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}

    .spinner::before,
    .spinner::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid #7cbd42;
        animation: prixClipFix 2s linear infinite;
    }

    .spinner::after {
        border-color: #7cbd42;
        animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
        inset: 6px;
    }

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}
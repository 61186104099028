
.controlContainer.checkbox {
    text-align: left;
}

.slider-container .preview3d{
    text-align: center;
}

.carousel-button {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    color: var(--color-orange);
    padding: 5px;
    border: 3px solid var(--color-orange);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

    .carousel-button.left {
        left: 0;
    }

    .carousel-button.right {
        right: 0;
    }


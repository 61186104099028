
.page.features {
    display: flex;    
}


.featureSection {
    display: block;
    width: 100%;
    text-align: center;
    margin: 50px 0px 50px 0px;
}

.featureSection p {
    text-align: center;
}

.buttonTile.feature .imageContainer {
    width: 250px;
    height: 200px;
    margin: 15px;
    background: #fff;
}

    .buttonTile.feature .imageContainer .picture {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }


    .popup.selectionConflictMessage {
        color: #fff;
        background: var(--color-orange);
    }


.popup.selectionConflictMessage .message {
    padding: 20px;
}
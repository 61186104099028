.agree-to-terms {
    display: flex;
    flex-direction: column;
}

.agree-to-terms-align-right {
    width: auto !important;
    margin-right: 0 !important;
    margin-left: auto;
}

.error-message {
    padding: 5px 0px 0px 5px;
    color: #bb1128;
    font-size: 12px;
}

/* Override style guide */
.skus table th.quantity {
    text-align: right;
}

.skus table td.quantity {
    text-align: right;
}

.skus table td.name {
    margin-left: 0px;
    text-align: right;
}

.skus table td.materialLength {
    margin-left: 0px;
    text-align: right;
}

.skus table th.materialLength {
    margin-left: 0px;
    text-align: right;
}


.skus.grandTotal .name {
    width: 0px;
    display: inline-flex;
    float: right;
    width: auto;
    justify-content: left;
}

.skus .payment-disabled-warning {
    margin-right: 200px;
    text-align: left;
}

.debug-card .card {
    background-color: lightyellow;
}

.debug-card > container {
    background-color: lightyellow;
}

.additionalShippingInfo {
    margin: 0px 0px 0px 15px;
    color: #C1C1C1;
}

.additionalShippingInfo {
    margin: 0px 0px 0px 15px;
    color: #C1C1C1;
    font-size:14px;
}

.selectedShippingRow {
    margin: 20px 0px 5px 0px;
}

.selectedShippingRow.primary {
    font-size: 17px;
}

.selectedShippingRow span.primaryLabel {
    color: #7CBD42;
    font-size: 14px;
}

.dielineImage {
    position: relative;
    display: inline-block;
    /*border: 1px solid #23b6e3;*/
    width: 100%;
    height: 700px;
    min-height: 450px;
    overflow: hidden;
    background: #fff;
}

    .dielineImage .menu {
        display: inline-block;
        height: 100%;
    }

    .dielineImage .menu .menuItem {
        margin: 10px 0px 10px 0px;
    }

        .dielineImage .menu.left {
            float: left;
            width: 200px;            
        }

        .dielineImage .menu.right {
            float: right;
            width: 50px;            
        }

    .dielineImage canvas {
        display: inline-block;
        width: calc(100% - 250px);
        cursor: grab;
    }

    .dielineImage .btn-round {
        color: #fff;
        background: var(--color-orange);
        border-color: var(--color-orange);
        width: 30px;
        height: 30px;
        margin: 0px 0px 20px 10px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        cursor: pointer;
    }

        .dielineImage .btn-round .icon {
            margin-top: 6px;
        }

        .dielineImage .error {
            position: absolute;
            z-index: 1001;
            width: 100%;
            background: #ee7728;
            color: #fff;
            padding: 10px;
            bottom: 0px;
            left: 0px;
        }

    .dielineImage .loading {
        display: block;
        text-align: center;
        background: #fff;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        position: absolute;
        z-index: 1010;
    }

        .dielineImage .loading .loader {
            width: 40px;
            height: 40px;
            margin: auto;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }


.fade-in {
    -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.fade-out {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0.25;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0.25;
    }

    100% {
        opacity: 1;
    }
}


@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.25;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.25;
    }
}


/*************************************************************/
/* MEDIA QUERIES                                            */
/*************************************************************/
@media (max-width: 1500px) {

    .dielineImage {
        height: 500px;
    }
}



@media (max-width: 420px) {

    .dielineImage {
        height: 150px;
    }

    .dielineImage .menu {
        display: none;
    }

    .dielineImage canvas {
        width: 100%;
    }
}
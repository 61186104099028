.fileupload {
    display: none
}

.fileupload label {
    font-weight: 400;
    color: #00B3E8;
    text-decoration: none;
}

.fileupload label:hover {
    cursor: pointer;
}

.upload.iconLink {
    cursor: pointer;
}

    .upload.iconLink label {
        cursor: pointer;
    }
.tabs .backsplash {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    opacity: 0.2;
    cursor: pointer;
}

.tabs .container {
    left: 10%;
    right: 10%;
    position: absolute;
    z-index: 1000;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

    .tabs .container.sticky {
        top: 100px;
        position: fixed;
    }


.tabs table {
    border-spacing: 0px;   
    border-collapse:collapse;
}


.tabs table tr {
    padding: 0px;
    margin: 0px;
}

    .tabs table td {
        text-align: left;
        padding: 0px;
        margin: 0px;
        white-space: nowrap;        
    }

.tabs .tab {
    margin: 0px 0px 0px 10px;
    display: flex;
    color: #fff;
    background: var(--color-blue);
    font-weight: 100;
    padding: 10px;
    display: inline-block;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    -webkit-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.44);
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.44);
    min-width: 150px;
    text-align: center;
}

.tabs .container .inactiveRow {
    background: #fff;
}

.tabs .container .contentRow {
    background: #fff;
}

    .tabs .container .contentRow .contentPanel {
        display: flex;
        padding: 10px;
    }


        .tabs .container .contentRow .contentPanel.hidden {
            overflow: hidden;
            max-height: 0;
            max-width: 0;
            margin: 0px;
            padding: 0px;
            transition: max-height 0.25s ease-out;
            transition: max-width 0.25s ease-out;
        }

        .tabs .container .contentRow .contentPanel.visible {
            padding-bottom: 115px;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 50vh;
            width: calc(100vw - 20%);
            transition: max-height 0.25s ease-in;
        }


.tabs .container .activeRow {
    border-top: 1px solid var(--color-blue);
}

    .tabs .container .activeRow.noborder,
    .tabs .container .inactiveRow.noborder,
    .tabs .container .contentRow.noborder {
        border: none;
    }







.tabs .contentPanel .tileContainer {
    margin: 0px;
}

.tabs .contentPanel .tabTileContent {
    width: inherit;
    height: inherit;
    padding: 10px 0px 0px 0px;
    text-align: center;
    cursor: pointer;
}

.tabs .contentPanel .tabTileContent h4 {
    margin-top: 0px;
}

    .tabs .contentPanel .tabTileContent .image {
        width: 80px;
        height: 80px;
        box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 25%);
        border-radius: 40px;
        margin-left: auto;
        margin-right: auto;
    }


.tabs .contentPanel .tabTileContent .content {
    overflow: hidden;
}



/*************************************************************/
/* MEDIA QUIERIES                                            */
/*************************************************************/

@media (max-width: 1024px) {

    .tabs .container {
        left: 5%;
        right: 5%;        
    }

    .tabs .tab {
        min-width: unset;
    }

    .tabs .contentPanel .tileContainer {
        justify-content: left;
        flex-wrap: nowrap;
    }

    .tabs .container .contentRow .contentPanel.visible {
        overflow-x: auto;
        overflow-y: hidden;
        width: calc(100vw - 10%);
    }
}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}
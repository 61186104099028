.dimensions {
}

    .dimensions .footerContent {
    }

        .dimensions .footerContent .popupText {
            text-align: center;
            font-size: 16px;
            color: #00B3E8;
            cursor: pointer;
            width: auto;
            top: 35px;
            padding: 25px 10px 10px 10px;
        }




    .dimensions .componentType {
        margin: 75px 0px 0px 0px;
    }


    .dimensions .tileContent .dims {
        text-align: center;
        display: block;
    }
    

    .dimensions .type {
        border-top: inset;
    }

    .dimensions .componentType .tileContent {
        width: 280px;
        display: block;
        padding: 5px;
    }

        .dimensions .componentType .tileContent .carousel {
            width: 270px;
        }

            .dimensions .componentType .tileContent .carousel .window {
                width: 215px;
            }


            .dimensions .componentType .tileContent .carousel .photo {
                width: 225px;
                height: 200px;
                display: block;
                overflow: hidden;
                background-position: center;
                background-repeat: no-repeat;
            }


.popup.customDimensions .contentSection input {
    margin: 10px 0px 10px 0px;
}

.popup.customDimensions .display {
    margin-top: 20px;
}


.popup.customDimensions .errors p {
    color:red;
}

.pouchDimensionImage .dimensionImage {
    position: relative;
    width: 280px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/*Three Side Seal Pouch*/
.pouchDimensionImage.ThreeSideSealPouch {
}

    .pouchDimensionImage.ThreeSideSealPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 224px;
        top: 140px;
    }

    .pouchDimensionImage.ThreeSideSealPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 135px;
        top: 44px;
    }

    .pouchDimensionImage.ThreeSideSealPouch .gussetLabel {
        display: none;
    }


/*Two Side Seal Pouch*/
.pouchDimensionImage.TwoSideSealPouch {
}

    .pouchDimensionImage.TwoSideSealPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 224px;
        top: 140px;
    }

    .pouchDimensionImage.TwoSideSealPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 135px;
        top: 44px;
    }

    .pouchDimensionImage.TwoSideSealPouch .gussetLabel {
        display: none;
    }



/*Plow Bottom Pouch*/
.pouchDimensionImage.PlowBottomPouch {
}

    .pouchDimensionImage.PlowBottomPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 210px;
        top: 115px;
    }

    .pouchDimensionImage.PlowBottomPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 90px;
        top: 65px;
    }

    .pouchDimensionImage.PlowBottomPouch .gussetLabel {
        position: absolute;
        z-index: 1;
        left: 161px;
        top: 186px;
    }

/*Skirt Seal Pouch*/
.pouchDimensionImage.SkirtSealPouch {
}

    .pouchDimensionImage.SkirtSealPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 210px;
        top: 115px;
    }

    .pouchDimensionImage.SkirtSealPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 90px;
        top: 65px;
    }

    .pouchDimensionImage.SkirtSealPouch .gussetLabel {
        position: absolute;
        z-index: 1;
        left: 161px;
        top: 186px;
    }


/*Doyen*/
.pouchDimensionImage.Doyen {
    display: none;
}

    .pouchDimensionImage.Doyen .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 210px;
        top: 115px;
    }

    .pouchDimensionImage.Doyen .widthLabel {
        position: absolute;
        z-index: 1;
        left: 90px;
        top: 65px;
    }

    .pouchDimensionImage.Doyen .gussetLabel {
        position: absolute;
        z-index: 1;
        left: 161px;
        top: 186px;
    }


/*Quad Seal Pouch*/
.pouchDimensionImage.QuadSealPouch {
}

    .pouchDimensionImage.QuadSealPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 204px;
        top: 140px;
    }

    .pouchDimensionImage.QuadSealPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 135px;
        top: 35px;
    }

    .pouchDimensionImage.QuadSealPouch .gussetLabel {
        position: absolute;
        z-index: 1;
        left: 102px;
        top: 248px;
    }

/*Fin Seal Pouch*/
.pouchDimensionImage.FinSealPouch {
}

    .pouchDimensionImage.FinSealPouch .lengthLabel {
        position: absolute;
        z-index: 1;
        left: 204px;
        top: 140px;
    }

    .pouchDimensionImage.FinSealPouch .widthLabel {
        position: absolute;
        z-index: 1;
        left: 135px;
        top: 35px;
    }

    .pouchDimensionImage.FinSealPouch .gussetLabel {
        position: absolute;
        z-index: 1;
        left: 102px;
        top: 248px;
    }




@media (max-width: 1024px) {
}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}


/* Modal */
.modal {
    display: none;
    position: fixed;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    margin: auto;
    width: 75%;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

.close-btn {
    float: right;
    color: lightgray;
    font-size: 24px;
    font-weight: bold;
}

    .close-btn:hover {
        color: darkgray;
    }

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/* Popover */
.popover {
    position: absolute;
    display: block;
    z-index: 20000;
    padding: 0px;
}

    .popover .triangle {
        margin: 0px 0px 0px calc(50% - 10px);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #F47745;
    }

    .popover .popover-content {
        box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
        border-radius: 6px;
        background: #FFFFFF;
        border: 1.5px solid #F47745;
        padding: 5px;
        color: var(--color-grey);
    }

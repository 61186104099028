.convertingMethod {
}
    .convertingMethod .componentType {
        margin: 75px 0px 0px 0px;
    }

        .convertingMethod h2, h3 {
            text-align: center;
            display: block;
        }

    .convertingMethod .componentType .tileContent {
        width: 270px;
        display: block;
        padding: 5px;
    }

    .convertingMethod .componentType .tileContent .carousel {
        width: 270px;
    }

        .convertingMethod .componentType .tileContent .carousel .window {
            width: 215px;
        }


        .convertingMethod .componentType .tileContent .carousel .photo {
            width: 225px;
            height: 200px;
            display: block;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }



@media (max-width: 1024px) {
}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}

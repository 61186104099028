.orderedProductLine .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.orderedProductLine .cell {
    padding: 10px 10px 10px 0px;
}

.orderedProductLine .caption {
    width: 300px;
    font-weight: lighter;
}

.orderedProductLine .content {
    font-weight: bold;
    width: 400px;
}

.orderedProductLine .feature {
    font-weight: bold;
    width: 200px;
}

.orderedProductLine .offset {
    font-weight: bold;
}

.orderedProductLine .button {
    text-align: right;
    vertical-align: bottom;
    padding: 20px 0px 20px 0px;
}

.orderedProductLine .button .btn {
     margin: 0px;
}

.orderedProductLine .button p {
    text-align: right;
    font-size: 16px;
    color: #00B3E8;
    margin: 0px;
}
.sectionModalContent .article-content .image-wrapper {
}

    .sectionModalContent .article-content .image-wrapper img {
        width: 100%;
    }

    .sectionModalContent .article-content .image-wrapper .caption {
        display: none
    }


.sectionModalContent .article-content ol
.sectionModalContent .article-content ul {
    list-style: square;
    margin: 0px;
    padding: 0px;
}


.sectionModalContent .article-content li {    
    display: block;
    margin: 0px;
}


.sectionModalContent .article-content table {    
}

    .sectionModalContent .article-content table th {
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #eee;
        margin: 0px;
    }

    .sectionModalContent .article-content table td {
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #eee;
        margin: 0px;
    }    
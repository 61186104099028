.clickable-image {
    padding: 15px;
}

.clickable-image img {
    border: 1px solid black;
}

.clickable-image .small {
    width: 150px;
    cursor: zoom-in;
}

.clickable-image .big {
    position: fixed;
    width: 95%;
    height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001;
    cursor: zoom-out;
}

.material h2, h3 {
    text-align: center;
    display: block;
}

.material .subTitle {
    width: 100%;
    text-align: center;
    margin: 0px 0px 50px 0px;
}


.material .filterComponent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 50px;
}

.material .filterPanel h2, h3 {
    text-align: left;
    display: block;
    margin: 10px 0px 10px 0px;
}

.material .filterPanel h3.clickable {
    padding: 4px 0px 4px 0px;

}

.material .filterPanel h3 .icon {
    height: 25px;
    vertical-align: middle;
    color: var(--color-blue);
}


.material .filterPanel {
    width: 400px;
    min-height: 50px;
    margin-right: 40px;
}

    .material .filterComponent .materialPanel {
        margin: 0px 10px 0px 10px;
        width: stretch;
        min-height: 50px;
    }

.material .filterPanel .filterSection {
    margin: 0px 0px 40px 0px;
}

    .material .filterPanel .filterSection.collapsible {
        -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);
        box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }


    .material .filterPanel .filterSection .sectionHeader {
        display: table;
        width: 100%;
    }

    .material .filterPanel .filterSection .sectionHeader h3 {
        text-align: left;
    }


        .material .filterPanel .filterSection .sectionHeader .text {
            padding: 0px;
            display: table-cell;
            word-break: break-word;
        }


        .material .filterPanel .filterSection .sectionHeader .controls {
            display: table-cell;
            width: 70px;
            padding: 0px 5px 0px 0px;
        }

            .material .filterPanel .filterSection .sectionHeader .controls h3 {
                text-align: right;
            }


    .material .filterPanel .filterSection .sectionContent {
        padding: 5px;
    }


    .checkboxContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.material .materialPanelItem {
    padding: 5px;    
    margin: 0px 0px 40px 0px;
}

    .material .materialPanelItem .panel {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

        .material .materialPanelItem .panel .filmVisualizer {
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0px 50px 0px 10px;
        }

        .material .materialPanelItem .panel .itemsPanel {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }


            .material .materialPanelItem .panel .itemsPanel .item {
                width: 180px;
                min-height: 50px;
                margin: 10px;                
            }


            .material .materialPanelItem:hover {
                border: 2px solid var(--color-blue);
                cursor: pointer;
            }

        .material .materialPanelItem:hover h4 {
            color: var(--color-blue);
        }

    .material .materialPanelItem h5 {
        margin: 0px 0px 10px 0px;
    }




/*CHECKBOX*/
.material .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid var(--color-blue);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.material .checkboxContainer .caption {
    padding-top: 2px;
    color: #000;
}

.material .checkboxContainer:hover input ~ .checkmark {
}

.material .checkboxContainer input:disabled ~ .checkmark {
    border: 1px solid var(--color-grey);
    cursor: not-allowed;
}

.material .checkboxContainer input:disabled ~ .caption {
    color: var(--color-grey);
    cursor: not-allowed;
}

.material .checkboxContainer input:checked ~ .checkmark {
}

.material .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.material .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
}

.material .checkboxContainer .checkmark:after {
    left: 6px;
    top: 4px;
    width: 5px;
    height: 8px;
    border: solid var(--color-blue);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.material .tab.filterTab {
    display: none;
}

.priceIndicator {

}

    .priceIndicator .symbol {
        line-height: 18px;
        text-align: center;
        width: 18px;
        height: 18px;
        font-family: Arial;
        font-size: 14px;
        font-weight: bold;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        border-radius: 14px;
        float: left;
        margin: 0px 3px 0px 0px;
    }

        .priceIndicator .symbol.active {
            color: #fff;
            background: var(--color-green);
            border: 1px solid var(--color-green);
        }

        .priceIndicator .symbol.inactive {
            color: var(--color-light-grey);
            background: #fff;
            border: 1px solid var(--color-light-grey);
        }


.material .toolbar {
    display: flex;
    justify-content: flex-start;
    min-height: 20px;
    width: 100%;
    margin: 0px 0px 20px 0px;
}

    .material .toolbar .btn {
        margin: 0px 0px 0px 10px;
    }



.guidedExperienceWizard {
    width: 100%;
    display: inline-block;
}

    .guidedExperienceWizard .topSection {
        display: flex;
        flex-flow: nowrap;
        align-content: center;
        width: 100%;
        min-height: 50px;
        color: #fff;
        background: #002B44;
        gap: 20px;        
    }
        
        .guidedExperienceWizard .topSection h4 {
            color: #fff;
            text-align: center;
        }

        .guidedExperienceWizard .topSection .left {
            padding: 20px 0px 0px 20px;
            flex-grow: 1;
        }

        .guidedExperienceWizard .topSection .center {
            flex-grow: 4;
        }

        .guidedExperienceWizard .topSection .right {
            padding: 20px 20px 0px 0px;
            flex-grow: 1;
            text-align: right;
        }


    .guidedExperienceWizard .contentSection {
        padding: 10px;
        max-height: 80vh;
        overflow-y: auto;
    }

        .guidedExperienceWizard .contentSection .resultItem {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            margin: 25px 0px 25px 0px;
            padding: 10px;
            text-align: left;
            border: 2px solid transparent;
        }

            .guidedExperienceWizard .contentSection .resultItem h3 {
                text-align: left;
                margin-top: 0px;
            }


            .guidedExperienceWizard .contentSection .resultItem:hover {
                border: 2px solid var(--color-blue);
                cursor: pointer;
            }
            
            .guidedExperienceWizard .contentSection .resultItem:hover h3 {
                color: var(--color-blue);
            }

            .guidedExperienceWizard .contentSection .resultItem .image {
            }

                .guidedExperienceWizard .contentSection .resultItem .image img {
                    max-width: 150px;
                    max-height: 150px;
                }


            .guidedExperienceWizard .contentSection .resultItem .explain {                
            }


.iconLabel {
    display: flex;
}

.iconLabel span {    
}

.iconLabel img {        
    height: 21px;
}



.materialPanelItem .panelTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

    .materialPanelItem .panelTitle .title {                
        flex-grow: 0;
    }

    .materialPanelItem .panelTitle .icon {
        flex-grow: 1;
        text-align: right;
        padding: 10px;
    }


/*************************************************************/
/* MEDIA QUIERIES                                            */
/*************************************************************/
@media (max-width: 1024px) {
    .material .filterComponent .filterPanel {
        display: none;
    }

    .material .tab.filterTab {
        display: flex;
    }

    .material .tabs .contentPanel.visible .filterPanel {
        height: 100%;
    }

        .material .tabs .container .contentRow .contentPanel.content1.visible::-webkit-scrollbar {
            display: none;
        }

    .material .tabs .container .contentRow .contentPanel.content1.visible {
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }


    .material .materialPanelItem table td {
        vertical-align: top;
    }

    .material .materialPanelItem .filmVisualizer {
        display: none;
    }


    .material .buttonbar {
        display: none;
    }

}

@media (max-width: 640px) {
    .material .toolbar {
        display: none;
    }
}

@media (max-width: 320px) {
}







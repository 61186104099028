/* NEW STYLES */

.skuEditor .quantitySelector {
    width: 100%;
}

.skuEditor .quantitySelector .customDropdown .item {
    display: inline-block;
    width: 100%;
    padding: 10px 0px 10px 0px;
}

.skuEditor .quantitySelector .customDropdown .item .quantity {
    display: block;
    float: left;
    padding: 0px 0px 0px 20px;
    font-weight: 600;
}

.skuEditor .quantitySelector .customDropdown .item .unitPrice {
    display: block;
    float: right;
    padding: 0px 20px 0px 0px;
    font-weight: 400;
}

.skuEditor .quantitySelector .customDropdown .item .unitPrice .money .currency,
.skuEditor .quantitySelector .customDropdown .item .unitPrice .money .amount {
    color: #7cbd42;
}

.skuEditor .quantitySelector .customDropdown .optionsContainer .listItem:hover .item .unitPrice .money .currency,
.skuEditor .quantitySelector .customDropdown .optionsContainer .listItem:hover .item .unitPrice .money .amount {
    color: #fff;
}

.skuEditor .quantitySelector .customDropdown .optionsContainer .listItem.disabled .item {
    background: #1886bb;
    color: #fff;
}

.skuEditor .alert {
    width: calc(100% - 40px);
}

.skuEditor .totals-row {
    margin-top: 30px;
}

.skuEditor .column.description {
    text-align: left;
}

.skuEditor .column.unitPrice {
    text-align: right;
}

.skuEditor .column.quantity {
    text-align: right;
}

.skuEditor .column.quantity input {
    text-align: right;
    padding: 0px 5px 0px 0px;
}

.skuEditor .column.price {
    text-align: right;
}

.skuEditor .column .money {
    margin-top: 10px;
}

.skuEditor .totals-row .column .money {
    margin-top: 0px;
}

.skuEditor .totals-row {
    font-size: 18px;
    font-weight: 800;
}

.skuEditor .totals-row input {
    border: none;
    cursor: text;
}

.skuEditor .row.title-row {
    margin-bottom: 20px;
}

.skuEditor .sectionTitle {
    width: auto;
    padding-bottom: 5px;
    padding-top: 10px;
}

.skuEditor .column.materialLength {
    text-align: right;
}

.skuEditor .sku-description {
    display: flex    
}

.skuEditor .popup {
    overflow: visible;
}

.skuEditor .clickable-image,
.skuEditor .empty-column {
    width: 150px;
    float: left;
    position: relative;
    padding: 5px;
    top: -20px;
}

/*************************************************************/
/* MEDIA QUERIES                                             */
/*************************************************************/
@media (max-width: 1000px) {
    .skuEditor .column.description {
        width: 33.33%;
        text-align: left;
    }

    .skuEditor .column.unitPrice {
        display: none;
    }

    .skuEditor .column.quantity {
        width: 33.33%;
        text-align: right;
    }

    .skuEditor .column.price {
        width: 33.33%;
        text-align: right;
    }

    .skuEditor .column .spacer {
        padding: 0px 20px 0px 20px;
    }

    .skuEditor .column:first-child .spacer {
        padding: 0px 20px 0px 0px;
    }

    .skuEditor .column:last-child .spacer {
        padding: 0px 0px 0px 20px;
    }

    .skuEditor .row.title-row h3 {
        font-size: 16px;
    }

    .skuEditor .row.title-row .icon {
        display: none;
    }
}

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes blink-1 {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

.betterPriceInfo {
    position: relative;
    margin: 0px 0px 20px 0px;
}

.betterPriceInfo .header {
    display: inline-block;
    background: #eee;
    width: 100%;
    padding: 12px;
    border: 1px solid #aaa;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.betterPriceInfo .expanded {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    -moz-border-radius-bottomright: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.betterPriceInfo .content-panel {
    position: absolute;
    top: 48px;
    z-index: 1000;
    background: #fafafa;
    width: 100%;
    padding: 12px;
    border: 1px solid #aaa;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-topright: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
}

.betterPriceInfo .message {
    float: left;
    display: inline-block;
    min-height: 20px;
}

.betterPriceInfo .toggle {
    float: right;
    display: inline-block;
    width: 25px;
    text-align: right;
    min-height: 20px;
}

.scale-in-ver-top {
    -webkit-animation: scale-in-ver-top 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-ver-top 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-out-ver-top {
    -webkit-animation: scale-out-ver-top 0.15s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: scale-out-ver-top 0.15s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
        display: inline-block;
    }
}

@keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
        display: inline-block;
    }
}

/**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
        display: none;
    }
}

@keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
        display: none;
    }
}

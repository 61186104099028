.artwork-selection .sku-list-container {
    display: grid;
}

.artwork-selection .sku-list-item {
    margin: 0px 20px 40px 0px;
}

@media (max-width: 1820px) {
    .artwork-selection .sku-list-container {
        grid-template-columns: auto;
    }
}

@media (min-width: 1820px) {
    .artwork-selection .sku-list-container {
        grid-template-columns: auto auto;
    }
}
.tabs .container .contentRow .contentPanel.visible {
    max-height: 60vh;
}


.tabs .container .contentRow .contentPanel {
    display: block;
}

.tabs .contentPanel .scroll {    
    width: 100%;
}

.tabs .contentPanel .wrapper {
    display: inline-block;
}

    .tabs .contentPanel .wrapper .tileContainer {
        display: inline-block;
    }

        .tabs .contentPanel .wrapper .tileContainer .buttonTile {
            display: block;
            float: left;
        }


        .tabs .contentPanel .tabTileContent {
            width: 200px;
            display: block;
        }

.tabs .contentPanel .tabTileContent .image {
    background-size: cover;
}

.tabs .contentPanel .tabTileContent .image .text {
    padding: 25px 0px 0px 0px;
}

.tabs .contentPanel .tabTileContent .caption {        
    font-size: 14px;
    white-space:normal;
    text-align: center;
}


.expandableTab .tileContainer {
    margin: 0px;
}

.tabs .contentPanel .buttonbar {
    /*position: absolute;*/
    display: block;
}

.expandableTab .tabTileContent {
    width: inherit;
    height: inherit;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

    .expandableTab .tabTileContent h4 {
        margin-top: 0px;
    }

    .expandableTab .tabTileContent .image {
        width: 80px;
        height: 80px;
        box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 25%);
        border-radius: 40px;
        margin-left: auto;
        margin-right: auto;
    }


    .expandableTab .tabTileContent .buttonTile .content {
        overflow: hidden;
    }

    


    /*************************************************************/
    /* MEDIA QUIERIES                                            */
    /*************************************************************/


@media (max-width: 1024px) {

    .tabs .contentPanel .scroll {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .tabs .contentPanel .wrapper {
        display: inline-block;
        width: 1465px;
    }


    .expandableTab .tileContainer {
        justify-content: left;
        flex-wrap: nowrap;
    }

    .expandableTab .content.visible {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .expandableTab .content .panel {
        border-left: none;
        border-right: none;
    }

    .tabs .container .contentRow .contentPanel.visible {
        overflow-x: auto;
        overflow-y: hidden;
        width: calc(100vw);
    }

    .tabs .container .contentRow .contentPanel.visible {
        max-height: 280px;
    }

}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}




@media (min-width: 1200px) {
    .registration-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .registration-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}

@media (max-width: 900px) {
    .registration-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
    }
}

.registration-container {
    padding-top: 20px;
    display: grid;
    grid-auto-rows: 1fr;
    place-content: center;
    margin: 1rem;
}

.registration-notifications {
    grid-column: 1 / -1;
    display: flex;
    justify-content: left;
}

.registration-submit {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
}

.registration-link {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    padding-top:5px;
}

    .registration-link p {
        text-decoration: none;
        color: #00B3E8;
        cursor: pointer;
    }

.registration-error {
    color: red;
    display: flex;
    justify-content: center;
}

.registration-grid-item {
    display: flex;
    min-width: 0;
    flex-direction: column;
}

.registration-validation-error {
    color: var(--color-error);
    padding: 4px;
    font-weight: bold;
}
.registration-info-icon {
    display: inline-block;
}



.contact-form-verification-container {
    display: grid;
    grid-auto-rows: 1fr;
    place-content: center;
    margin: 1rem;
}
.contact-form-verification-grid-item {
    display: flex;
    min-width: 0;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
}
.contact-form-verification-error {
    color: var(--color-error);
    padding: 4px;
    font-weight: bold;
}



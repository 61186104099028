.artworkInstructions .article-content h1,
.artworkInstructions .article-content h2 {
    font-size: 24px;
    font-weight: 600;
    color: #002b44;
    margin-top: 50px;
}

.artworkInstructions .article-content h3,
.artworkInstructions .article-content h4,
.artworkInstructions .article-content h5 {
    margin-top: 50px;
}

.artworkInstructions .article-content li,
.artworkInstructions .article-content p {
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #5a5b5d;
    font-size: 16px;
}

.artworkInstructions .article-content ul {
    list-style: square;
    font-weight: 400;
}

.artworkInstructions .article-content ol li {
    margin-bottom: unset;
}

.artworkInstructions .article-content li {
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #5a5b5d;
    font-size: 16px;
}

.offsetEditor .text {
    font-size: 14px;    
    border: none;
}

    .offsetEditor .error {        
        color: #BB1128;
    }


.offsetEditor .iconLink {
    width: unset;
    float: left;
    min-width: 100px;
    padding-right: 20px;
}

    .offsetEditor .iconLink.disabled {
        cursor: default;
    }

    .font-size-sm {
        font-size: 16px;
        font-weight: 400;
        float: left;
        max-width: 230px;
    }
.grid-container {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr 4fr;
    /*    background: linear-gradient(#000, #000) center/2px 100% no-repeat;*/
    grid-gap: 0px;
    margin: 10px 0px 0px 0px;
}

    .grid-container .border {
        position: absolute;
        left: 50%;
        width: 2px;
        height: 100%;
        background: grey;
        right: -9px
    }

.grid-header {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    justify-content: flex-end;
    color: var(--color-dark-blue);
    font-size: large;
}

    .grid-header > div {
        text-align: center;
    }

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 20px 0px 20px 0px;
    cursor: pointer;

}

    .grid-item label.savings {
        color: var(--color-green);
    }

    .grid-item.border-right {
        border-right: 2px solid #aaa;
    }


    .grid-item.selected {
        color: var(--color-blue);
        font-size: larger;
        border-bottom: solid;
        border-top: solid;
        background: #d3d3d366;
        border-color: black;
    }

        .grid-item.selected.leftSelect {
            border-left: solid;
            border-color: black;
            border-radius: 15px 0px 0px 15px;
        }
        .grid-item.selected.left{
            margin-left:30px;
        }
        .grid-item.selected.right {
            margin-right: 30px;
        }
        .grid-item.selected.rightSelect {
            border-right: solid;
            border-color: black;
            border-radius: 0px 15px 15px 0px;
        }

        .grid-item.selected .savings {
            color: var(--color-green);
        }


    .grid-item.nosell {
        color: var(--color-orange);
        text-decoration: line-through;
    }


    .grid-item .skuRadioButton {
        transform: scale(1.5);
        margin: 0px 10px 0px 0px;
    }

.myOrdersTable {
    padding-top: 50px;
}

    .myOrdersTable .submitAction .icon {
        color: red;
    }

    .myOrdersTable .action {
        font-size: 14px;
    }

.home .regionSelectModal {
    width: 100%;
    display: inline-block;
}

    .home .regionSelectModal .topSection {
        display: flex;
        flex-flow: nowrap;
        align-content: center;
        width: 100%;
        min-height: 50px;
        color: #fff;
        background: #002B44;
        gap: 20px;
    }

        .home .regionSelectModal .topSection h4 {
            color: #fff;
            text-align: center;
        }

        .home .regionSelectModal .topSection .left {
            padding: 20px 0px 0px 20px;
            flex-grow: 1;
        }

        .home .regionSelectModal .topSection .center {
            flex-grow: 4;
        }

        .home .regionSelectModal .topSection .right {
            padding: 20px 20px 0px 0px;
            flex-grow: 1;
            text-align: right;
        }


    .home .regionSelectModal .contentSection {        
        max-height: 90vh;
        overflow-y: auto;
    }

        .home .regionSelectModal .contentSection .resultItem {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            margin: 25px 0px 25px 0px;
            padding: 10px;
            text-align: left;
            border: 2px solid transparent;
        }

            .home .regionSelectModal .contentSection .resultItem h3 {
                text-align: left;
                margin-top: 0px;
            }


            .home .regionSelectModal .contentSection .resultItem:hover {
                border: 2px solid var(--color-blue);
                cursor: pointer;
            }

                .home .regionSelectModal .contentSection .resultItem:hover h3 {
                    color: var(--color-blue);
                }

            .home .regionSelectModal .contentSection .resultItem .image {
            }

            .home .regionSelectModal .contentSection .resultItem .explain {
            }
.order h3 {
    text-align: left;
}

.order .card .sectionTitle {
    display: inline-block;
    width: 100%;
    padding-bottom: 5px;
    padding-top: 10px;
}

.order .skuEditor .column.description h3 {
    text-align: left;
}

.order .skuEditor .sectionTitle {
    padding-top: 20px;
}

.order .skuEditor .column.unitPrice h3,
.order .skuEditor .column.quantity h3,
.order .skuEditor .column.price h3 {
    text-align: right;
}

.order .skuEditor input[name="skuTotal"] {
    border: none;
    line-height: 0px;
}

.container {
    padding: 30px 5% 150px 5%;
}
.selectableSkuLineItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    border: 2px solid #eee;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 5%);
    overflow: hidden;
    cursor: pointer;
}

.selectableSkuLineItem.unchecked {
    border-color: var(--color-grey);
}

.selectableSkuLineItem.checked {
    border-color: var(--color-blue);
}

.selectableSkuLineItem .selector {
    text-align: center;
    align-content: center;
    width: 50px;
    background: #eee;
}

.selectableSkuLineItem.checked .selector {
    background: var(--color-blue);
}

.selectableSkuLineItem.unchecked .selector {
    background: var(--color-grey);
}

.selectableSkuLineItem .selector input {
    width: 20px;
    height: 20px;
}

.selectableSkuLineItem .selectable-area {
    display: inherit;
}

.selectableSkuLineItem .sectionLabel {
    margin-right: 10px;
    margin-left: 30px;
    display: table;
}

.selectableSkuLineItem .sectionLabel .description {
    display: table-cell;
    vertical-align: middle;
    float: none;
    width: 100%;
}

.selectableSkuLineItem .iconLink {
    align-content: center;
}

.selectableSkuLineItem .iconLink .icon {
    width: auto;
    height: 25px;
}

@media (max-width: 520px) {
    .selectableSkuLineItem .sectionLabel {
        width: 100px;
        margin-left: 10px;
        font-size: 15px;
    }

    .selectableSkuLineItem .selector {
        width: 30px;
    }

    .selectableSkuLineItem .selector input {
        width: 15px;
        height: 15px;
    }

    .selectableSkuLineItem .clickable-image {
        padding: 10px;
    }

    .selectableSkuLineItem .iconLink .icon {
        height: 20px;
    }

    .selectableSkuLineItem .clickable-image .small {
        width: 100px;
    }
}

@media (min-width: 520px) and (max-width: 750px) {
    .selectableSkuLineItem .sectionLabel {
        width: 150px;
        margin-left: 12px;
        font-size: 18px;
    }

    .selectableSkuLineItem .clickable-image .small {
        width: 150px;
    }
}

@media (min-width: 750px) and (max-width: 950px) {
    .selectableSkuLineItem .sectionLabel {
        width: 250px;
        margin-left: 20px;
    }

    .selectableSkuLineItem .clickable-image .small {
        width: 200px;
    }
}

@media (min-width: 950px) {
    .selectableSkuLineItem .sectionLabel {
        width: 350px;
        margin-left: 30px;
    }

    .selectableSkuLineItem .clickable-image .small {
        width: 250px;
    }
}
.btn-square {
    margin: 0px 20px 0px 0px;
    padding: 0px;
    height: 100px;
    width: 100px;
    position: relative;
}

.btn-square .state {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 3px solid #f47745;
    color: #000;
    width: 30px;
    height: 30px;
    background: #fff;
    position: absolute;
    top: -15px;
    left: -15px;
}

.btn-square .state .icon {
    padding: 5px 0px;
    color: #7cbd42;
}

.card .title {
    width: auto
}
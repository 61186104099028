/* THIS SHOULD BE MOVED TO STYLE GUIDE APP */

#feedbackMsg::placeholder {
    color: transparent;
}

.overflow-hidden {
    overflow: hidden;
}

input:valid, .control.valid, .dropdown {
    border: 2px solid #EEE;
}

/* GRID LAYOUT */
.col,
.col-1,
.col-sm,
.col-sm-1,
.col-md,
.col-md-1,
.col-lg,
.col-lg-1 {
    width: unset;
}

.col-2,
.col-sm-2,
.col-md-2,
.col-lg-2 {
    width: unset;
}

.col-3,
.col-sm-3,
.col-md-3,
.col-lg-3 {
    width: unset;
}

.col-4,
.col-sm-4,
.col-md-4,
.col-lg-4 {
    width: unset;
}

.col-5,
.col-sm-5,
.col-md-5,
.col-lg-5 {
    width: unset;
}

.col-6,
.col-sm-6,
.col-md-6,
.col-lg-6 {
    width: unset;
}

.col-7,
.col-sm-7,
.col-md-7,
.col-lg-7 {
    width: unset;
}

.col-8,
.col-sm-8,
.col-md-8,
.col-lg-8 {
    width: unset;
}

.col-9,
.col-sm-9,
.col-md-9,
.col-lg-9 {
    width: unset;
}

.col-10,
.col-sm-10,
.col-md-10,
.col-lg-10 {
    width: unset;
}

.col-11,
.col-sm-11,
.col-md-11,
.col-lg-11 {
    width: unset;
}

.col-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    width: unset;
}

.col-sm-hidden,
.col-md-hidden,
.col-lg-hidden {
    display: unset;
}


@media (min-width: 1023px) {
    .col,
    .col-1,
    .col-lg,
    .col-lg-1 {
        width: calc((100% / 12) * 1);
    }

    .col-2,
    .col-lg-2 {
        width: calc((100% / 12) * 2);
    }

    .col-3,
    .col-lg-3 {
        width: calc((100% / 12) * 3);
    }

    .col-4,
    .col-lg-4 {
        width: calc((100% / 12) * 4);
    }

    .col-5,
    .col-lg-5 {
        width: calc((100% / 12) * 5);
    }

    .col-6,
    .col-lg-6 {
        width: calc((100% / 12) * 6);
    }

    .col-7,
    .col-lg-7 {
        width: calc((100% / 12) * 7);
    }

    .col-8,
    .col-lg-8 {
        width: calc((100% / 12) * 8);
    }

    .col-9,
    .col-lg-9 {
        width: calc((100% / 12) * 9);
    }

    .col-10,
    .col-lg-10 {
        width: calc((100% / 12) * 10);
    }

    .col-11,
    .col-lg-11 {
        width: calc((100% / 12) * 11);
    }

    .col-12,
    .col-lg-12 {
        width: calc((100% / 12) * 12);
    }

    .col-lg-hidden {
        display: none;
    }
}


/* MD */
@media (max-width: 1023px) {
    .col,
    .col-1,
    .col-md,
    .col-md-1 {
        width: calc((100% / 12) * 1);
    }

    .col-2,
    .col-md-2 {
        width: calc((100% / 12) * 2);
    }

    .col-3,
    .col-md-3 {
        width: calc((100% / 12) * 3);
    }

    .col-4,
    .col-md-4 {
        width: calc((100% / 12) * 4);
    }

    .col-5,
    .col-md-5 {
        width: calc((100% / 12) * 5);
    }

    .col-6,
    .col-md-6 {
        width: calc((100% / 12) * 6);
    }

    .col-7,
    .col-md-7 {
        width: calc((100% / 12) * 7);
    }

    .col-8,
    .col-md-8 {
        width: calc((100% / 12) * 8);
    }

    .col-9,
    .col-md-9 {
        width: calc((100% / 12) * 9);
    }

    .col-10,
    .col-md-10 {
        width: calc((100% / 12) * 10);
    }

    .col-11,
    .col-md-11 {
        width: calc((100% / 12) * 11);
    }

    .col-12,
    .col-md-12 {
        width: calc((100% / 12) * 12);
    }

    .col-md-hidden {
        display: none;
    }
}


/* SM */
@media (max-width: 480px) {
    .col,
    .col-sm,
    .col-sm-1 {
        width: calc((100% / 12) * 1);
    }

    .col-2,
    .col-sm-2 {
        width: calc((100% / 12) * 2);
    }

    .col-3,
    .col-sm-3 {
        width: calc((100% / 12) * 3);
    }

    .col-4,
    .col-sm-4 {
        width: calc((100% / 12) * 4);
    }

    .col-5,
    .col-sm-5 {
        width: calc((100% / 12) * 5);
    }

    .col-6,
    .col-sm-6 {
        width: calc((100% / 12) * 6);
    }

    .col-7,
    .col-sm-7 {
        width: calc((100% / 12) * 7);
    }

    .col-8,
    .col-sm-8 {
        width: calc((100% / 12) * 8);
    }

    .col-9,
    .col-sm-9 {
        width: calc((100% / 12) * 9);
    }

    .col-10,
    .col-sm-10 {
        width: calc((100% / 12) * 10);
    }

    .col-11,
    .col-sm-11 {
        width: calc((100% / 12) * 11);
    }

    .col-12,
    .col-sm-12 {
        width: calc((100% / 12) * 12);
    }

    .col-sm-hidden {
        display: none;
    }


    .btn {
        min-width: unset;
    }
}

.reviewQuantitiesStep {
}

    .reviewQuantitiesStep .controlContainer {
        pointer-events: none;
    }

        .reviewQuantitiesStep .controlContainer .checkbox {
            cursor: pointer;
            pointer-events: all;
        }

        .reviewQuantitiesStep .controlContainer .payload {
            cursor: pointer;
            pointer-events: all;
        }

    .reviewQuantitiesStep .plantPickup.controlContainer {
        margin: 30px 0px 0px 0px;
        pointer-events: none;
    }

        .reviewQuantitiesStep .plantPickup.controlContainer .checkbox {
            margin: 10px 10px 0px 0px;
            pointer-events: all;
        }

        .reviewQuantitiesStep .plantPickup.controlContainer .payload {
            margin: 2px 0px 0px 0px;
            pointer-events: all;
        }

.plantPickupControl {
    width: 100%;
}

    .plantPickupControl .required {
        display: inline-block;
    }
/*    .plantPickupControl span {
        display: inline;
    
    }
        .plantPickupControl  p {
            display: inline;
      
            margin: 5px 5px 5px 5px;
        }
    .plantPickupControl .customDropdown {
        width: 30%;
       
    }*/

.additionalShippingAddress {
    border: solid #959596;
    border-radius: 5px;
    margin: 30px 0px 0px 0px;
    padding: 15px;
}
.shippingLocationList {
    margin: 0px 0px 0px 15px;
}
    .shippingLocationList h4 {
        font-weight: bolder;
    }
    .shippingLocationList a {
        padding: 15px;
    }
.selectedShippingRow {
    margin: 0px 0px 15px 0px;
}
    .selectedShippingRow .column.w100 {
        width: 100%;
    }
    .selectedShippingRow .column.w75 {
        width: 75%;
    }
    .selectedShippingRow .column.w50 {
        width: 50%;
    }
    .selectedShippingRow .column.w25 {
        width: 25%;
    }

    .selectedShippingRow .address {
        padding-right: 20px;
    }



body {
}



.headerLabel {
    width: fit-content;
    padding: 10px 10px 10px 0px;
    margin-right: 25px;
}


    .headerLabel.inactive :hover {
        transform: scale(1.1);
    }

    .headerLabel.active button {
        border: 3px solid #6C66A5;
        transform: scale(1.2);
    }

        .headerLabel.active button:hover {
        }

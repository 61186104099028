#card_CC .container {
    /*height: 200px;*/
    overflow: hidden;
}

/*
@media (max-width: 775px) {
    #card_CC .container {
        height: 300px;
    }
}
*/

#card_Check .container {
    height: 500px;
    overflow: hidden;
}

@media (max-width: 775px) {
    #card_Check .container {
        height: 640px;
    }

    #card_Check .container #mycontainerCheck {
        height: 640px;
    }
}
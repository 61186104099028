.confirmation.popup .content {
    padding: 10px;
    text-align: center;
}

.confirmation.popup .content h3 {
    margin-top: 0px;
}

.confirmation.popup .button {
    color: #fff;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 20px 0px 20px 0px;
    cursor: pointer;
    float: left;
    border: 0px;
    background: #7CBD42;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

    .confirmation.popup .button.left {
        background: #F47745;
        border-bottom-right-radius: unset;
        width: 50%;
    }

    .confirmation.popup .button.right {
        background: #7CBD42;
        border-bottom-left-radius: unset;
        width: 50%;
    }

.popup-header {
    display: flex;
    color: #fff;
    background: #002b44;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;
    min-height: 50px;
    align-items: center;
}

.popup-content {
    text-align: left;
}
.orderSummaryLayout .contextHelp {
    height: unset;
}

.orderSummaryLayout .container h3 {
    text-align: left;
}

.orderSummaryLayout .artwork-waived-info {
    margin-top: 50px;
}